import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"

const Projects = ({ data }) => {
  let {
    allWpProject: { nodes: posts },
  } = data

  // console.log(posts)

  return (
    <>
      {posts &&
        posts.map((post, index) => {
          return (
            <div key={index} className="mt-5">
              <h1 className="mb-1">{post.title}</h1>
              <p style={{ fontSize: "1.1em" }}>
                {post?.acf_project_fields?.location}
              </p>
              <div>{parse(post.content)}</div>
              <ul className="mb-5">
                <li>
                  <strong>Scope: </strong> {post?.acf_project_fields?.scope}
                </li>
                <li>
                  <strong>Category: </strong>
                  {post?.acf_project_fields?.category}
                </li>
                <li>
                  <strong>Size: </strong> {post?.acf_project_fields?.size}
                </li>
                <li>
                  <strong>Year: </strong> {post?.acf_project_fields?.year}
                </li>
              </ul>
            </div>
          )
        })}
    </>
  )
}

export const query = graphql`
  {
    allWpProject(sort: { fields: [taxServices___nodes___name], order: DESC }) {
      nodes {
        title
        content
        acf_project_fields {
          location
          scope
          category
          size
          year
        }
      }
    }
  }
`

export default Projects
